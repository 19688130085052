import { CircularProgress } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { TFunction } from 'i18next'
import { parse } from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useAppState } from '../../../appState'
import editIcon from '../../../assets/images/edit-info.svg'
import igIcon from '../../../assets/images/instagram.svg'
import igIconHover from '../../../assets/images/social/instagram-white.svg'
import uploadIconHover from '../../../assets/images/upload-hover.svg'
import uploadIcon from '../../../assets/images/upload.svg'
import axios from '../../../axios'
import { baseURL, COLORS, ENDPOINTS, ROUTES } from '../../../constants'
import CHRUploadAvatar from '../../AmplifyUpload'
import CHRButton from '../../button'
import ContentContainer from '../../contentContainer'
import Input from '../../input'
import AddFromInstagramModal from '../addFromInstagramModal'
import ConnectInstagramModal from '../connectInstagramModal'
import CreateNewFolderModal from '../createNewFolderModal'
import SBSort from '../sbSort'
import ShareShoppingBoardModal from '../shareShoppingBoardModal'
import SwitchAccountsModal from '../swithAccountsModal'
import { styles } from './styles'
import { errorToast } from '../../../../utils/errToast'
import { setItemToLocalStorage } from '../../../../utils/setLocalStorage'
import Links from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
interface BoardProfileProps {
  t: TFunction
  handleSortChange: any
  options: any
  nonUserOptions: any
  shareUrl: string
  selectedSort: any
  canEdit: boolean
  reloadShopping: any
  shoppingUserProfile: any
  handleSBoardItemsChange: Function
  folderView: boolean
  shoppingBoardItems: any[]
  handleCreateFolder: Function
}

const BoardProfile = ({
  t,
  handleSortChange,
  options,
  nonUserOptions,
  shoppingUserProfile: shoppingUserProfileInit,
  selectedSort,
  canEdit,
  handleSBoardItemsChange,
  folderView = false,
  shoppingBoardItems,
  handleCreateFolder,
}: BoardProfileProps) => {
  const classes = styles()

  const { search } = useLocation()
  const { redirectCode } = parse(search)

  const [appState, dispatch] = useAppState()
  const [isModal, setIsModal] = useState(false)
  const [isFolderModal, setIsFolderModal] = useState(false)
  const [isEditingBio, setIsEditingBio] = useState(false)
  const [isEditingBlogLink, setIsEditingBlogLink] = useState(false)
  const [shortBio, setShortBio] = useState('')
  const [blogLink, setBlogLink] = useState('')
  const shortBioInput = useRef(null)
  const blogLinkInput = useRef(null)
  const [isModalIg, setIsModalIg] = useState(false)
  const [instaModal, setInstaModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isIgPrivate, setIsIgPrivate] = useState()
  const [isFetchingIgPosts, setIsFetchingIgPosts] = useState(true)
  const [igErr, setIgErr] = useState('')
  const [shoppingUserProfile, setShoppingUserProfile] = useState(
    shoppingUserProfileInit
  )
  const shareUrl = `${process.env.GATSBY_SITE_URL}/s/${shoppingUserProfile.username}`
  const [instagramUrl, setInstagramUrl] = useState(
    shoppingUserProfileInit.socialUrls
      ? JSON.parse(shoppingUserProfileInit.socialUrls).instagramUrl
      : ''
  )
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down(1600), { noSsr: true })
  const [igNextProducts, setIgNextProducts] = useState('')
  const [isSwitchModalOpen, setIsSwitchModalOpen] = useState(false)

  const closeModal = () => {
    setIsModal(false)
  }

  const closeCreateNewFolderModal = () => {
    setIsFolderModal(false)
  }

  const closeModalIg = () => {
    setIsModalIg(false)
  }

  const addItemToShoppingBoard = () => {
    navigate('/user/share-with-friends')
  }

  const handleCreateShareLinkClick = () => {
    setIsModal(true)
  }

  const handleCreateNewFolderClick = () => {
    setIsFolderModal(true)
  }

  const truncateUserName = (name: string = '') => {
    const maxLength = isTablet ? 10 : 23
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '...'
    }
    return name
  }

  const onSortChange = (event: any) => {
    handleSortChange(event)
  }

  const focusShortBioInput = () => {
    setIsEditingBio(true)
    shortBioInput.current.focus()
  }
  const focusBlogLinkInput = () => {
    setIsEditingBlogLink(true)
    blogLinkInput.current.focus()
  }

  const updateShortBio = async (shortBio: string) => {
    try {
      if (shoppingUserProfile.shortBio == shortBio) {
        return
      }
      await axios.patch(
        `${baseURL}/${ENDPOINTS.user.replace(':id', appState.userId)}`,
        {
          shortBio: shortBio,
        }
      )

      setShoppingUserProfile(s => ({ ...s, shortBio }))
    } catch (err) {
      errorToast(`couldn't update bio, please try again`)
    }
  }
  const updateBlogLink = async (blogLink: string) => {
    try {
      if (shoppingUserProfile.blogLink == blogLink) {
        return
      }
      await axios.patch(
        `${baseURL}/${ENDPOINTS.user.replace(':id', appState.userId)}`,
        {
          blogLink,
        }
      )

      setShoppingUserProfile(s => ({ ...s, blogLink }))
    } catch (err) {
      errorToast(`couldn't update blog link, please try again`)
    }
  }

  const updateShoppingProfile = (data: any) => {
    setShoppingUserProfile(data)
  }

  // useEffect(() => {
  // const getR = async () => {
  // const ress = await handleGetIgPosts(
  //   `https://www.instagram.com/web/search/topsearch/?query=sheikh.fazeel`
  // )

  // const ress = await fetch(
  //   'https://www.instagram.com/web/search/topsearch/?query=sheikh.fazeel',
  //   { mode: 'cors' }
  // )
  // const ress = await handleGetIgPosts(
  //   `https://www.instagram.com/shujahm/`
  // )
  // console.log(ress)

  // const res = await axios.get('https://www.instagram.com/sheikh.fazeel/?__a=1&__d=dis')
  // const res = await axios.get('https://imginn.org/sheikh.fazeel/')
  // console.log(res)

  // }
  // getR()
  // }, [])

  useEffect(() => {
    setShoppingUserProfile(shoppingUserProfile)
    setShortBio(shoppingUserProfile.shortBio)
    setBlogLink(shoppingUserProfile.blogLink)
  }, [shoppingUserProfile.shortBio])

  const attachSbIdsToIgPosts = (items: any) => {
    const shoppingBoardItemsIds = new Map()

    shoppingBoardItems.forEach(e => {
      if (e.type === 'instagram') {
        shoppingBoardItemsIds.set(e.data.post_id, e.id)
      }
    })

    return items.map((item: any) => {
      if (shoppingBoardItemsIds.has(item.id)) {
        return { ...item, shoppingBoardId: shoppingBoardItemsIds.get(item.id) }
      } else {
        return item
      }
    })
  }

  const handleGetIgPosts = async (url: string) => {
    // had to use fetch api here since axios can't bypassing cors errors that is thrown here in case of error
    const IG_RATE_LIMIT_EXCEEDED_ERR_CODE = 4
    const apiCall = () => fetch(url, { mode: 'cors' })
    let attempts = 3
    let resBody

    while (attempts > 0) {
      attempts -= 1
      const res = await apiCall()
      const body = await res.json()

      if (res.ok) {
        resBody = body
        break
      } else if (body.error?.code === IG_RATE_LIMIT_EXCEEDED_ERR_CODE) {
        throw new Error('Try again later')
      } else {
        if (attempts === 0) {
          throw new Error('Try again later')
        }

        continue
      }
    }

    const { paging, data } = resBody
    const items = data.filter(
      ({ media_type }: any) =>
        media_type === 'IMAGE' || media_type === 'CAROUSEL_ALBUM'
    )
    const itemWithSbIds = attachSbIdsToIgPosts(items)

    return { items: itemWithSbIds, paging }
  }

  // current ig api doesn't provide direct way to check for private accounts
  // we try to get the first post image, if we can't load it then most likely it is a private account
  const handleCheckIgPrivateAccount = async (items: any) => {
    if (items.length > 0) {
      try {
        await axios.get(
          `/api/v1/ig?igUrl=${encodeURIComponent(
            items[0].permalink + 'media/?size=l'
          )}`
        )
        setIsIgPrivate(false)
      } catch (err) {
        if (err.response?.status === 400) {
          // error indicates that the account is private
          setIsIgPrivate(true)
        }
      }
    }
  }

  const fetchIgPosts = async (userId: number, accessToken: string) => {
    setLoading(true)
    setIgErr('')

    let items
    let paging

    try {
      const res = await handleGetIgPosts(
        `https://graph.instagram.com/me/media?fields=id,username,media_url,permalink,media_type&access_token=${accessToken}&limit=100`
      )
      items = res.items
      paging = res.paging
    } catch (err) {
      setIgErr('Something went wrong, please try again later.')
      return
    }

    await handleCheckIgPrivateAccount(items)

    // initial ig items
    const instagramInfo = {
      total: items.length,
      userId,
      items,
    }
    dispatch({
      type: 'UPDATE_INSTAGRAM_INFOMATION',
      instagramInfo,
    })
    setLoading(false)
    setIsModalIg(true)
    setIsFetchingIgPosts(false)

    // if initial item less than the required number to show container scroll
    // then we keep fetching until we meet this number or consume all user ig posts
    const MINIMUM_SHOWED_POSTS = 13
    let nextUrl = paging?.next
    while (instagramInfo.total < MINIMUM_SHOWED_POSTS && nextUrl) {
      try {
        const { items: nextItems, paging: nextPaging } = await handleGetIgPosts(
          nextUrl
        )
        nextUrl = nextPaging?.next

        instagramInfo.items = [...instagramInfo.items, ...nextItems]
        instagramInfo.total = instagramInfo.items.length

        dispatch({
          type: 'UPDATE_INSTAGRAM_INFOMATION',
          instagramInfo,
        })
      } catch (err) {
        setIgErr('Unable to fetch more posts, please try again later.')
        return
      }
    }

    setIgNextProducts(nextUrl)
    setIsFetchingIgPosts(false)
  }

  const onScrollLoadMore = async () => {
    if (igNextProducts) {
      let items
      let paging

      try {
        const res = await handleGetIgPosts(igNextProducts)
        items = res.items
        paging = res.paging
      } catch (err) {
        setIgErr('Unable to fetch more posts, please try again later.')
        return
      }

      const igItems = [...appState.instagramInfo.items, ...items]
      const instagramInfo = {
        items: igItems,
        total: igItems.length,
      }

      dispatch({
        type: 'UPDATE_INSTAGRAM_INFOMATION',
        instagramInfo,
        userId: appState.instagramInfo.userId,
      })
      setIgNextProducts(paging?.next)
      setIsFetchingIgPosts(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (redirectCode) {
        window.history.pushState('', '', '?')
        setLoading(true)

        let accessToken
        let userId

        try {
          const res = await axios.post(
            '/api/v1/shopping-boards/verify-instagram',
            {
              code: redirectCode,
            }
          )
          accessToken = res.data.data.accessToken
          userId = res.data.data.userId
        } catch (err) {
          setLoading(false)
          return
        }

        if (accessToken) {
          setItemToLocalStorage(
            'ig_token',
            JSON.stringify({ accessToken, userId, timestamp: Date.now() })
          )

          return fetchIgPosts(userId, accessToken)
        }
      }
    })()
  }, [])

  const handleOpenInstaAuthWindow = () => {
    const clientId = process.env.GATSBY_IG_CLIENT_ID
    const redirectUri = process.env.GATSBY_IG_REDIRECT_URI
    window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`
  }

  const createFolderCloseModal = value => {
    return handleCreateFolder(value).then(() => setIsFolderModal(false))
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isFetchingIgPosts,
    hasNextPage: Boolean(igNextProducts),
    onLoadMore: onScrollLoadMore,
    disabled: Boolean(igErr),
    rootMargin: '0px 0px 0px 0px',
  })

  const handleAddFromInstagram = async () => {
    const igToken = JSON.parse(window.localStorage.getItem('ig_token'))
    const isIgTokenExpired =
      igToken && (igToken.timestamp || 0) + 3600000 < Date.now()
    if (igToken && !isIgTokenExpired) {
      try {
        await fetchIgPosts(igToken.userId, igToken.accessToken)
      } catch (err) {
        // most likely the token has expired, lets get a new one
        handleOpenInstaAuthWindow()
      }
    } else {
      handleOpenInstaAuthWindow()
    }
  }

  return (
    <>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress style={{ color: COLORS.black }} size={40} />
        </div>
      )}
      <article className={classes.profileWrapper}>
        <ContentContainer>
          <div className={classes.breadcrumb}>
            <Breadcrumbs aria-label="breadcrumb">
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(ROUTES.home)
                }}
              >
                home
              </Links>
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(ROUTES.theChirpyest)
                }}
              >
                chirpyest community
              </Links>
              <Typography color="textPrimary">
                {shoppingUserProfile.username.toLowerCase() ||
                  shoppingUserProfile.fullName.toLowerCase()}
                's shopping board
              </Typography>
            </Breadcrumbs>
          </div>
        </ContentContainer>
        <ContentContainer customStyle={classes.profileInner}>
          <section className={classes.personalInfo}>
            {canEdit && !shoppingUserProfile.imageUrl ? (
              <label
                className={classes.avatarCircle}
                htmlFor="upload-dashboard-avatar"
                title="Change Profile Image"
              >
                <CHRUploadAvatar
                  inputId="upload-image-settings-tab"
                  circleInputId="upload-dashboard-avatar"
                  shoppingUserProfile={shoppingUserProfile}
                  updateShoppingProfile={updateShoppingProfile}
                  canEdit={canEdit}
                />
              </label>
            ) : (
              <div className={classes.avatarCircle}>
                <CHRUploadAvatar
                  inputId="upload-image-settings-tab"
                  shoppingUserProfile={shoppingUserProfile}
                  canEdit={canEdit}
                />
              </div>
            )}
            <div className={classes.rightInfo}>
              <Typography
                variant="h1"
                component="h2"
                classes={{ h1: classes.userName }}
              >
                {shoppingUserProfile && (
                  <>
                    {truncateUserName(shoppingUserProfile.username) ||
                      truncateUserName(shoppingUserProfile.fullName)}
                  </>
                )}
              </Typography>
              {!canEdit && (
                <Typography variant="h3" className={classes.profileBio}>
                  {shoppingUserProfile.shortBio}
                </Typography>
              )}
              {canEdit && (
                <div className={classes.editingBio}>
                  <Input
                    type="text"
                    // placeholder={t('shoppingBoard.addShortBio')}
                    value={shortBio}
                    customStyles={`${classes.shortBioInput} ${
                      isEditingBio ? classes.shortBioInputShow : ''
                    }`}
                    elementRef={shortBioInput}
                    onChange={(event: any) => {
                      setShortBio(event.target.value)
                    }}
                    onFocus={() => {
                      // setIsEditingBio(true)
                    }}
                    onBlur={async () => {
                      setIsEditingBio(false)
                      await updateShortBio(shortBio)
                    }}
                  />
                  {!isEditingBio && !shortBio && (
                    <button
                      type="button"
                      className={classes.shortLabel}
                      onClick={focusShortBioInput}
                    >
                      <span>{t('shoppingBoard.addShortBio')}</span>
                    </button>
                  )}
                  <div
                    className={`${classes.shortText} ${
                      isEditingBio ? classes.shortTexthHide : ''
                    }`}
                  >
                    {shortBio}
                  </div>
                  {shortBio && (
                    <>
                      <button
                        type="button"
                        className={`${classes.btnEdit} ${
                          isEditingBio ? classes.btnEditHide : ''
                        }`}
                        onClick={focusShortBioInput}
                      >
                        {editIcon && (
                          <img
                            src={editIcon}
                            className={classes.btnIcon}
                            alt="buttonIcon"
                          />
                        )}
                      </button>
                    </>
                  )}
                </div>
              )}
              {/* BLOG SITE */}
              {!canEdit && (
                <Typography
                  variant="subtitle1"
                  className={classes.profileBio}
                  component="p"
                >
                  <a
                    href={
                      shoppingUserProfile.blogLink?.match(
                        /^http[s]*:\/\/[\w]+/i
                      )
                        ? shoppingUserProfile.blogLink
                        : `http://${shoppingUserProfile.blogLink}`
                    }
                    target="_blank"
                    className={classes.blogLink}
                  >
                    {shoppingUserProfile.blogLink}
                  </a>
                </Typography>
              )}
              {canEdit && (
                <div className={classes.editingBio}>
                  <Input
                    type="text"
                    // placeholder={t('shoppingBoard.addShortBio')}
                    value={blogLink}
                    customStyles={`${classes.shortBioInput} ${
                      classes.blogLinkInput
                    } ${isEditingBlogLink ? classes.shortBioInputShow : ''}`}
                    elementRef={blogLinkInput}
                    onChange={(event: any) => {
                      setBlogLink(event.target.value)
                    }}
                    onFocus={() => {
                      // setIsEditingBio(true)
                    }}
                    onBlur={async () => {
                      setIsEditingBlogLink(false)
                      await updateBlogLink(blogLink)
                    }}
                  />
                  {!isEditingBlogLink && !blogLink && (
                    <button
                      type="button"
                      className={classes.shortLabel}
                      onClick={focusBlogLinkInput}
                    >
                      <span>{t('shoppingBoard.addBlogLink')}</span>
                    </button>
                  )}
                  <div
                    className={`${classes.shortText} ${
                      isEditingBlogLink ? classes.shortTexthHide : ''
                    }`}
                    style={{ fontSize: 14 }}
                  >
                    <a
                      className={classes.blogLink}
                      href={
                        blogLink?.match(/^http[s]*:\/\/[\w]+/i)
                          ? blogLink
                          : `http://${blogLink}`
                      }
                      target="_blank"
                    >
                      {blogLink}
                    </a>
                  </div>
                  {blogLink && (
                    <>
                      <button
                        type="button"
                        className={`${classes.btnEdit} ${
                          isEditingBlogLink ? classes.btnEditHide : ''
                        }`}
                        onClick={focusBlogLinkInput}
                      >
                        {editIcon && (
                          <img
                            src={editIcon}
                            className={classes.btnIcon}
                            alt="buttonIcon"
                          />
                        )}
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </section>
          <section
            className={`${classes.profileActions} ${!canEdit &&
              classes.sortOptionsWrapper}`}
          >
            {canEdit && (
              <CHRButton
                label={t('shoppingBoard.addItemToBoard')}
                onClick={addItemToShoppingBoard}
                customStyle={classes.btn}
                hoverEffects={true}
              />
            )}
            {canEdit && !folderView && (
              <CHRButton
                icon={igIcon}
                iconHover={igIconHover}
                label={t('shoppingBoard.addFromInstagram')}
                onClick={handleAddFromInstagram}
                customStyle={`${classes.btn} ${classes.btnOther}`}
                hoverEffects={true}
              />
            )}
            {canEdit &&
              (!folderView ||
              !(
                appState.userProfile.isPro || appState.userProfile.isCreator
              ) ? null : ( // for now null
                <CHRButton
                  label={t('shoppingBoard.createNewFolder')}
                  onClick={handleCreateNewFolderClick} // TODO : open create folder modal
                  customStyle={classes.btn}
                  hoverEffects={true}
                />
              ))}
            {canEdit &&
              (!folderView || isTablet ? (
                <CHRButton
                  icon={uploadIcon}
                  iconHover={uploadIconHover}
                  label={t('shoppingBoard.shareShoppingBoard')}
                  onClick={handleCreateShareLinkClick}
                  customStyle={`${classes.btn} ${classes.btnOther}`}
                  hoverEffects={true}
                />
              ) : (
                <div
                  className={classes.shareBtnWraapper}
                  onClick={handleCreateShareLinkClick}
                >
                  <img src={uploadIcon} alt="share icon" />
                </div>
              ))}
            {canEdit && (
              <SBSort
                options={options}
                selectedItem={selectedSort}
                onSortChange={onSortChange}
              />
            )}
            {!canEdit && (
              <SBSort
                options={nonUserOptions}
                selectedItem={selectedSort}
                onSortChange={onSortChange}
              />
            )}
          </section>
        </ContentContainer>
        <ShareShoppingBoardModal
          handleClose={closeModal}
          openStatus={isModal}
          shareUrl={shareUrl}
        />
        <CreateNewFolderModal
          handleClose={closeCreateNewFolderModal}
          openStatus={isFolderModal}
          shareUrl={shareUrl}
          handleCreateFolder={createFolderCloseModal}
        />
        <AddFromInstagramModal
          handleClose={closeModalIg}
          openStatus={isModalIg}
          isIgPrivate={isIgPrivate}
          loading={isFetchingIgPosts}
          error={igErr}
          handleSBoardItemsChange={handleSBoardItemsChange}
          rootRef={rootRef}
          sentryRef={sentryRef}
          hasNextPage={Boolean(igNextProducts)}
          shoppingBoardItems={shoppingBoardItems}
          handleOpenInstaAuthWindow={handleOpenInstaAuthWindow}
          handleSwitchAccount={() => {
            setIsSwitchModalOpen(true)
            closeModalIg()
          }}
        />
        <ConnectInstagramModal
          handleClose={() => setInstaModal(false)}
          openStatus={instaModal}
          socialUrls={
            shoppingUserProfileInit.socialUrls
              ? JSON.parse(shoppingUserProfileInit.socialUrls)
              : {}
          }
          userId={shoppingUserProfileInit.id}
          handleAfterConnect={(url: string) => {
            setInstagramUrl(url)
            setInstaModal(false)
            handleOpenInstaAuthWindow()
          }}
        />
        <SwitchAccountsModal
          openStatus={isSwitchModalOpen}
          handleClose={() => setIsSwitchModalOpen(false)}
        />
      </article>
    </>
  )
}
export default withTranslation()(BoardProfile)
